// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("latinotype_-_goldplay_regular-webfont.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("latinotype_-_goldplay_regular-webfont.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("latinotype_-_goldplay_regular_it-webfont.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("latinotype_-_goldplay_regular_it-webfont.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("latinotype_-_goldplay_medium-webfont.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("latinotype_-_goldplay_medium-webfont.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("latinotype_-_goldplay_medium_it-webfont.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("latinotype_-_goldplay_medium_it-webfont.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("latinotype_-_goldplay_semibold-webfont.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("latinotype_-_goldplay_semibold-webfont.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_10___ = new URL("latinotype_-_goldplay_semibold_it-webfont.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_11___ = new URL("latinotype_-_goldplay_semibold_it-webfont.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_12___ = new URL("Goldplay-LightReg.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_13___ = new URL("Goldplay-LightIt.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_14___ = new URL("Goldplay-LightIt.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
var ___CSS_LOADER_URL_REPLACEMENT_12___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_12___);
var ___CSS_LOADER_URL_REPLACEMENT_13___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_13___);
var ___CSS_LOADER_URL_REPLACEMENT_14___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_14___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:Goldplay;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");font-weight:400;font-style:normal}@font-face{font-family:Goldplay;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\");font-weight:400;font-style:italic}@font-face{font-family:Goldplay;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff\");font-weight:500;font-style:normal}@font-face{font-family:Goldplay;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"woff\");font-weight:500;font-style:italic}@font-face{font-family:Goldplay;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format(\"woff\");font-weight:600;font-style:normal}@font-face{font-family:Goldplay;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_11___ + ") format(\"woff\");font-weight:600;font-style:italic}@font-face{font-family:Goldplay;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_12___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_12___ + ") format(\"woff\");font-weight:100;font-style:normal}@font-face{font-family:Goldplay;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_13___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_14___ + ") format(\"woff\");font-weight:100;font-style:italic}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
